import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Backlink from '@sherweb/core/components/Backlink'
import { NoResults } from '@sherweb/core/components/NoResults'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestHavingNoResults, isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import Link from '@rsp/components/Link'
import { useGetCatalogProductsQuery, useGetCatalogQuery } from '@rsp/modules/martkeplace'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { useGetFilteredCatalogProducts } from './hooks/useGetFilteredCatalogProducts'
import { MarketplaceCatalogActions } from './MarketplaceCatalogActions'
import { MarketplaceCatalogAddProductCard } from './MarketplaceCatalogAddProductCard'
import { MarketplaceCatalogProductCard } from './MarketplaceCatalogProductCard'
import { MarketplaceCatalogProductPageLoader } from './MarketplaceCatalogProductPageLoader'
import { MarketplaceCatalogSearch } from './MarketplaceCatalogSearch'

const MarketplaceCatalogProductPage = () => {
  const { t } = useTranslation()

  const [searchText, setSearchText] = useState('')

  const { catalogId } = useGetCatalogId()

  const catalogProductsQuery = useGetCatalogProductsQuery(catalogId)

  const catalogQuery = useGetCatalogQuery(catalogId)

  const hasNoResults = isRequestHavingNoResults(catalogProductsQuery)

  const { filteredProducts } = useGetFilteredCatalogProducts({
    catalogProducts: catalogProductsQuery?.data,
    searchText,
  })

  const renderContent = () => {
    if (hasNoResults) {
      return <MarketplaceCatalogAddProductCard />
    }

    if (filteredProducts?.length === 0) {
      return <NoResults dataTestId="CatalogProducts" />
    }

    return filteredProducts?.map(product => (
      <MarketplaceCatalogProductCard key={product.id} product={product} />
    ))
  }

  return (
    <>
      <ContainedPage>
        {isRequestLoading(catalogProductsQuery) || isRequestLoading(catalogQuery) ? (
          <MarketplaceCatalogProductPageLoader />
        ) : (
          <div className="flex flex-col gap-12 md:gap-16">
            <div className="flex flex-col gap-2">
              <Link to={Routes.Marketplace} data-testid="linkBackToCatalogs">
                <Backlink>{t('rsp:pages.marketplace.catalogs.backToCatalogs')}</Backlink>
              </Link>
              <PageTitleWrapper
                title={
                  <PageTitle data-testid="pageCatalogProductsTitle">
                    {catalogQuery?.data?.name}
                  </PageTitle>
                }
                subTitle={
                  <PageSubTitle data-testid="pageCatalogProductsSubitle">
                    {catalogQuery?.data?.description}
                  </PageSubTitle>
                }
              />
            </div>
            {!hasNoResults ? (
              <div className="flex flex-col gap-2">
                <MarketplaceCatalogActions>
                  <Typography variant="heading5" weight="bold">
                    {t('rsp:pages.marketplace.catalogs.allProducts')}
                  </Typography>
                </MarketplaceCatalogActions>
                <MarketplaceCatalogSearch searchText={searchText} setSearchText={setSearchText} />
              </div>
            ) : null}
            <div className="mt-4 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                {renderContent()}
              </div>
            </div>
          </div>
        )}
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(MarketplaceCatalogProductPage, Permission.CatalogProductsList)
