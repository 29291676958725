import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import * as z from 'zod'

import config from '@config'

import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import Spinner from '@sherweb/core/components/Spinner'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { usePortalMetadata } from '@sherweb/core/modules/portalMetadata'

import Routes from '@ssp/app/Routes'
import LanguageSelector from '@ssp/components/LanguageSelector'
import FullWidthPage from '@ssp/components/layout/FullWidthPage'

import { LoginHeader } from './LoginHeader'
import loginFormSchema from './validationSchema/loginFormSchema'

const RELOAD = true

export const LoginPage = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const { data: portalMetadata } = usePortalMetadata()

  const { isLoggedIn, isLoading: isAuthenticationLoading } = useAuthenticationLoggedInState(RELOAD)

  const { bff } = config

  const signInUrl = `${bff.url}${bff.signInPath}`

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      username: '',
    },
  })

  function onSubmit(values: z.infer<typeof loginFormSchema>) {
    setIsLoading(true)
    const redirectTo: string = location.state?.from.toString()
    window.location.href = `${signInUrl}${encodeURIComponent(values.username)}&redirectTo=${redirectTo}`

    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  if (isAuthenticationLoading) {
    return <Spinner floating />
  }

  if (isLoggedIn) {
    return <Navigate replace to={Routes.Root} />
  }

  return (
    <FullWidthPage>
      <div className="flex min-h-screen bg-slate-900 dark:bg-gradient-to-l dark:from-slate-900 dark:to-slate-950">
        <div className="hidden w-auto p-4 md:flex md:w-1/3 lg:w-1/2">
          {/* Left-side content (if needed) */}
        </div>
        <div className="flex h-screen w-full flex-col items-center justify-center bg-white dark:bg-slate-900  md:w-2/3 lg:w-1/2">
          <div className="top-0 flex h-14 w-full justify-between bg-white px-4 shadow-sm dark:bg-slate-900 md:px-8 md:py-4">
            <div />
            <div className="flex flex-row justify-center gap-4">
              <div className="flex flex-row items-center gap-1">
                <div className="dark:text-slate-200">
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-screen w-full max-w-2xl flex-col items-center p-4 pt-8 md:mt-16 md:justify-start md:px-16 md:pb-32 md:pt-8">
            <div className="flex w-full max-w-md flex-col gap-12">
              <LoginHeader
                resellerLogoURL={portalMetadata.logo}
                resellerName={portalMetadata.resellerName}
              />
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <div className="flex w-full flex-col gap-8">
                    <div className="w-full">
                      <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t('ssp:pages.login.loginform.username.label')}</FormLabel>
                            <FormControl>
                              <FormInput
                                data-testid="usernameField"
                                placeholder={t('ssp:pages.login.loginform.username.placeholder')}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <LoaderButton
                      isLoading={isLoading}
                      dataTestId="signin"
                      type="submit"
                      className="w-full"
                      loadingChildren={t('ssp:pages.login.loginform.signingIn')}
                    >
                      {t('ssp:pages.login.loginform.signIn')}
                    </LoaderButton>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </FullWidthPage>
  )
}

export default LoginPage
