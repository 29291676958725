import {
  CatalogListQueryResult,
  AvailableCatalogProductQueryResult,
  CatalogCategory,
  AvailableCatalogOffer,
  BillingCycle,
  CommitmentTermType,
  PricingModelType,
  Money,
  Currency,
  CatalogProductQueryResult,
  CatalogProductCategory,
  CatalogOffer,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ResellerCatalogsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAvailableCatalogsByResellerId(
    params: {
      /**  */
      resellerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogListQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/catalogs';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAvailableProducts(
    params: {
      /**  */
      resellerId: string;
      /**  */
      catalogId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AvailableCatalogProductQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/catalogs/{catalogId}/available-products';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{catalogId}', params['catalogId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProducts(
    params: {
      /**  */
      resellerId: string;
      /**  */
      catalogId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogProductQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/catalogs/{catalogId}/products';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{catalogId}', params['catalogId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
