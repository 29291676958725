import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'

import { useGetCatalogId } from '../hooks/useGetCatalogId'

type MarketplaceCatalogActionsProps = {
  children: React.ReactNode
}

export const MarketplaceCatalogActions = ({ children }: MarketplaceCatalogActionsProps) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { catalogId } = useGetCatalogId()

  return (
    <div className="flex w-full justify-between">
      {children}
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild data-testid="dropdownManageCatalog">
          <Button variant="None" className="bg-indigo-600 px-4 py-2 hover:bg-indigo-700">
            {t('rsp:pages.marketplace.catalogs.manageCatalog')}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="min-w-[230px]"
          onFocusOutside={() => {
            setDropdownOpen(false)
          }}
        >
          <DropdownMenuItem data-testid="dropdownItemManageProducts" asChild>
            <Link
              to={Routes.MarketplaceCatalogAddProducts}
              params={{
                catalogId: catalogId ?? '',
              }}
            >
              {t('rsp:pages.marketplace.catalogs.manageProducts')}
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
