import { useTranslation } from 'react-i18next'

import { CatalogProductQueryResult, Money } from '@sherweb/core/openapi-generated/index.defs'

import Badge from '@sherweb/core/components/Badge'
import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'
import { Vendor } from '@sherweb/core/modules/marketplace/ui/Vendor'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

type MarketplaceCatalogProductCardProps = {
  product: CatalogProductQueryResult
}

export const MarketplaceCatalogProductCard = ({ product }: MarketplaceCatalogProductCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter<Money>(language)

  return (
    <Card
      key={product.id}
      className="flex min-h-56 flex-col justify-between md:p-4"
      data-testid="cardCatalogProduct"
    >
      <div className="flex h-full flex-col justify-between gap-2">
        <div>
          <Vendor vendorType={product.vendorName} className="mb-2 h-[16px]" />
          <CardTitle className="min-h-12 text-slate-900 dark:text-slate-200">
            <Typography
              variant="body1"
              weight="semiBold"
              className="line-clamp-3 text-wrap sm:line-clamp-2"
              data-testid="productName"
            >
              {product.name}
            </Typography>
          </CardTitle>
          <div className="flex flex-wrap items-center gap-x-2">
            <div className="mt-3 flex flex-col items-start gap-2">
              <Typography variant="body2">
                {t('rsp:pages.marketplace.catalogs.billingCycle')}/
                {t('rsp:pages.marketplace.catalogs.commitmentTerm')}
              </Typography>
              <div className="flex flex-wrap gap-1">
                {product.offers?.map(offer => (
                  <Badge
                    data-testid="productOffer"
                    key={offer.sku}
                    className="dark:bg-slate-950 dark:text-slate-200"
                  >
                    {t(`rsp:pages.marketplace.catalogs.billingCycleValues.${offer.billingCycle}`)}/
                    {t(
                      `rsp:pages.marketplace.catalogs.commitmentValues.${offer.commitmentTermType}`
                    )}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="body1" weight="semiBold" data-testid="productPrice">
            {product.startingPrice && formatMoneyAmount(product.startingPrice)}
          </Typography>
          <Typography variant="body2" colors="light">
            {t('rsp:pages.marketplace.catalogs.startingPrice')}
          </Typography>
        </div>
      </div>
    </Card>
  )
}
