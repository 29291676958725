import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { CatalogListQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { buildAvailableProducts } from './marketplace.builder'
import { availableCatalogs, availableProducts, catalogProducts } from './marketplace.queries'

export const useGetAvailableCatalogsQuery = <TResult = CatalogListQueryResult[]>(
  options?: Omit<UseQueryOptions<CatalogListQueryResult[], Error, TResult>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<CatalogListQueryResult[], Error, TResult>({
    queryKey: availableCatalogs.queryKey(resellerId),
    queryFn: async () => await availableCatalogs.queryFn(resellerId),
    enabled: !!resellerId,
    staleTime: availableCatalogs.staleTime,
    ...options,
  })
}

export const useGetCatalogQuery = (catalogId?: string) => {
  return useGetAvailableCatalogsQuery({
    select: catalogs => catalogs?.find(catalog => catalog.id === catalogId),
  })
}

export const useGetCatalogProductsQuery = (catalogId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: catalogProducts.queryKey(resellerId, catalogId),
    queryFn: async () => await catalogProducts.queryFn(resellerId, catalogId),
    enabled: !!resellerId && !!catalogId,
    staleTime: catalogProducts.staleTime,
  })
}

export const useGetAvailableProductsQuery = (catalogId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: availableProducts.queryKey(resellerId, catalogId),
    queryFn: async () => await availableProducts.queryFn(resellerId, catalogId),
    select: products => buildAvailableProducts(products),
    enabled: !!resellerId && !!catalogId,
    staleTime: availableProducts.staleTime,
  })
}
