import { PlusIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Card from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'

import { useGetCatalogId } from '../hooks/useGetCatalogId'

export const MarketplaceCatalogAddProductCard = () => {
  const { t } = useTranslation()

  const { catalogId } = useGetCatalogId()

  const { navigate } = useNavigate()

  const handleGoToAddProductsPage = () => {
    navigate(Routes.MarketplaceCatalogAddProducts, {
      catalogId: catalogId ?? '',
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleGoToAddProductsPage()
    }
  }

  return (
    <Card
      className="flex h-56 flex-col justify-between border-2 border-dashed border-slate-400 hover:cursor-pointer focus-visible:outline-indigo-300 md:p-4"
      data-testid="cardAddProductsToCatalog"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleGoToAddProductsPage}
    >
      <div className="flex h-full flex-col items-center justify-center gap-2">
        <PlusIcon className="h-8 w-8 stroke-2 text-slate-500" />
        <Typography className="text-slate-500 dark:text-slate-200">
          {t('rsp:pages.marketplace.catalogs.addProducts')}
        </Typography>
      </div>
    </Card>
  )
}
