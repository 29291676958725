import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

type useHasSelectedOffersChangedProps = {
  alreadyAddedOffers: ICatalogOffer[]
  selectedOffers: ICatalogOffer[]
}
export const useHasSelectedOffersChanged = ({
  alreadyAddedOffers,
  selectedOffers,
}: useHasSelectedOffersChangedProps) => {
  const isDirty = !(
    alreadyAddedOffers.length === selectedOffers.length &&
    alreadyAddedOffers.every(alreadyAddedOffer =>
      selectedOffers.some(selectedOffer => selectedOffer.sku === alreadyAddedOffer.sku)
    )
  )

  return { isDirty }
}
