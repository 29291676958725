import { AvailableCatalogProductQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { IAvailableCatalogProductQueryResult, ICatalogOffer } from './marketplace.model'

type BuildAvailableProductsReturn = {
  selectedOffers: ICatalogOffer[]
  totalOffersCount: number
  products: IAvailableCatalogProductQueryResult[]
}

export const buildAvailableProducts = (
  products?: AvailableCatalogProductQueryResult[]
): BuildAvailableProductsReturn => {
  if (!products) {
    return {
      totalOffersCount: 0,
      selectedOffers: [],
      products: [],
    }
  }

  let selectedOffers: ICatalogOffer[] = []
  let totalOffersCount = 0

  const updatedProducts = products?.map(product => {
    totalOffersCount = totalOffersCount + (product?.offers?.length ?? 0)
    return {
      ...product,
      offers:
        product?.offers?.map(offer => {
          if (offer.isSelected) {
            selectedOffers = [...selectedOffers, { ...offer, id: offer.sku }]
          }
          return {
            ...offer,
            id: offer.sku,
          }
        }) ?? [],
    }
  })

  return { selectedOffers, totalOffersCount, products: updatedProducts ?? [] }
}
