import { CatalogProductQueryResult } from '@sherweb/core/openapi-generated/index.defs'

type UseGetFilteredCatalogProductsProps = {
  searchText: string
  catalogProducts?: CatalogProductQueryResult[] | undefined
}

export const useGetFilteredCatalogProducts = ({
  catalogProducts,
  searchText,
}: UseGetFilteredCatalogProductsProps) => {
  if (!catalogProducts) {
    return { filteredProducts: [] }
  }

  const filteredProducts = catalogProducts.filter(
    product =>
      searchText === '' ||
      (product.name ?? '')?.toLowerCase().includes(searchText.toLowerCase()) ||
      (product.vendorName ?? '')?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.offers?.some(offer =>
        (offer.sku ?? '').toLowerCase().includes(searchText.toLowerCase())
      )
  )

  return { filteredProducts }
}
