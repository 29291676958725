import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@sherweb/core/components/Drawer'
import { Skeleton, SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { useGetAvailableProductsQuery, useGetCatalogQuery } from '@rsp/modules/martkeplace'
import { useNavigate } from '@rsp/modules/navigation'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { MarketplaceCatalogsAddProductsTable } from './MarketplaceCatalogsAddProductsTable'

const MarketplaceCatalogsAddProducts = () => {
  const { t } = useTranslation()

  const { catalogId } = useGetCatalogId()

  const availableProducts = useGetAvailableProductsQuery(catalogId)

  const catalogQuery = useGetCatalogQuery(catalogId)

  const { navigate } = useNavigate()

  const redirectToCatalogProductsPage = () => {
    navigate(Routes.MarketplaceCatalogProducts, {
      catalogId: catalogId ?? '',
    })
  }

  return (
    <Drawer open>
      <DrawerContent className="ml-0 mr-[6px] flex h-3/4 flex-col outline-none lg:ml-[260px]">
        <>
          <DrawerHeader
            className="p-4 pb-10 text-start md:pl-12"
            onClose={() => {
              redirectToCatalogProductsPage()
            }}
          >
            <DrawerTitle className="dark:text-white">
              {isRequestLoading(catalogQuery) ? (
                <Skeleton className="h-[18px] w-[264px]" />
              ) : (
                t('rsp:pages.marketplace.addProducts.title', { name: catalogQuery?.data?.name })
              )}
            </DrawerTitle>
            <DrawerDescription className="dark:text-white">
              {t('rsp:pages.marketplace.addProducts.description')}
            </DrawerDescription>
          </DrawerHeader>
          {isRequestLoading(availableProducts) ? (
            <div className="p-4 md:pl-12">
              <SkeletonTable count={4} />
            </div>
          ) : (
            <MarketplaceCatalogsAddProductsTable />
          )}
          <DrawerFooter className="sticky bottom-2 p-4 pt-6">
            <div className="flex justify-end gap-4">
              <Button
                variant={Variant.Outline}
                onClick={() => {
                  redirectToCatalogProductsPage()
                }}
              >
                {t('core:actions.cancel')}
              </Button>
              <Button disabled={isRequestLoading(availableProducts)}>
                {t('rsp:pages.marketplace.addProducts.updateCatalog')}
              </Button>
            </div>
          </DrawerFooter>
        </>
      </DrawerContent>
    </Drawer>
  )
}

export default protectPage(MarketplaceCatalogsAddProducts, Permission.CatalogProductsEdit)
