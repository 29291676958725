import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Money } from '@sherweb/core/openapi-generated/index.defs'

import { Checkbox } from '@sherweb/core/components/Checkbox'
import {
  CollapsibleDataContent,
  CollapsibleDataTable,
} from '@sherweb/core/components/DataTable/CollapsibleDataTable'
import {
  CollapsibleColumn,
  RenderChecboxCellProps,
  RenderCheckboxHeaderProps,
} from '@sherweb/core/components/DataTable/CollapsibleDataTable/types'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { orderBy } from '@sherweb/core/utils/array'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { useGetAvailableProductsQuery } from '@rsp/modules/martkeplace'
import {
  IAvailableCatalogProductQueryResult,
  ICatalogOffer,
} from '@rsp/modules/martkeplace/core/marketplace.model'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { useHasSelectedOffersChanged } from './hooks/useHasSelectedOffersChanged'
import { UseSelectProps, useSelectRow } from './hooks/useSelectRow'

const initialSort: { id: keyof IAvailableCatalogProductQueryResult; ascending: boolean } = {
  id: 'vendorName',
  ascending: true,
}

const renderCheckboxHeader = (
  props: RenderCheckboxHeaderProps<IAvailableCatalogProductQueryResult>,
  selectAll: (props: {
    handleSelectAll: () => void
    data: IAvailableCatalogProductQueryResult[]
    currentSelectedAll: boolean
  }) => void
) => {
  const { checked, ...restProps } = props

  return (
    <Checkbox
      variant="secondary"
      data-testid="checkbox-header"
      checked={checked}
      onClick={() => {
        selectAll(restProps)
      }}
    />
  )
}

const renderCheckboxCell = (
  props: RenderChecboxCellProps<IAvailableCatalogProductQueryResult, ICatalogOffer>,
  selectRow: (props: { isCollapsible?: boolean } & UseSelectProps) => void
) => (
  <Checkbox
    data-testid={`checkbox-${props?.data?.sku as string}`}
    variant="secondary"
    checked={props?.data?.isSelected}
    onClick={event => {
      event.stopPropagation()
    }}
    onCheckedChange={checked => {
      selectRow({
        ...props,
        checked,
      })
    }}
  />
)

export const MarketplaceCatalogsAddProductsTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { catalogId } = useGetCatalogId()

  const availableProducts = useGetAvailableProductsQuery(catalogId)

  const formatMoneyAmount = useMoneyFormatter<Money>(language)

  const [selectedOffers, setSelectedOffers] = useState<ICatalogOffer[]>(
    availableProducts?.data?.selectedOffers ?? []
  )

  const { isDirty } = useHasSelectedOffersChanged({
    alreadyAddedOffers: availableProducts?.data?.selectedOffers ?? [],
    selectedOffers,
  })

  usePrompt({
    isDirty,
  })

  const { selectAll, selectRow } = useSelectRow({
    setSelectedRows: setSelectedOffers,
  })

  const columns: CollapsibleColumn<IAvailableCatalogProductQueryResult, ICatalogOffer> = [
    {
      id: 'select',
      header: props => renderCheckboxHeader(props, selectAll),
      cell: props => renderCheckboxCell(props, selectRow),
    },
    {
      id: 'vendorName',
      header: t('rsp:pages.marketplace.addProducts.table.vendor'),
      onSort: ({ ascending, setData, data }) => {
        setData?.(orderBy(data, 'vendorName', !ascending))
      },
      render({ parentData, data }) {
        return parentData?.vendorName ?? data?.vendor
      },
    },
    {
      accessorKey: 'sku',
      header: t('rsp:pages.marketplace.addProducts.table.sku'),
    },
    {
      accessorKey: 'name',
      className: 'col-span-4',
      header: t('rsp:pages.marketplace.addProducts.table.productName'),
      onSort: ({ ascending, setData, data }) => {
        setData?.(orderBy(data, 'name', !ascending))
      },
    },
    {
      accessorKey: 'price',
      header: t('rsp:pages.marketplace.addProducts.table.price'),
      render({ data }) {
        return data?.price && formatMoneyAmount(data?.price)
      },
    },
  ]

  const handleSelectAll = (isSelectedAll: boolean) => {
    // TODO: Remove this when user can perform the action to add products to catalogs
    console.log('isSelectedAll', isSelectedAll)
  }

  if (!availableProducts?.data) {
    return null
  }

  return (
    <CollapsibleDataTable
      columns={columns}
      data={availableProducts?.data?.products}
      isHeaderFixed
      nestedChildrenTotalCount={availableProducts?.data?.totalOffersCount}
      nestedChildrenType="offers"
      initialSort={initialSort}
      selectedRows={selectedOffers}
      onSelectAll={handleSelectAll}
      renderCollapsibleRow={contentProps => (
        <ScrollArea className="mt-9 flex grow overflow-y-auto py-4">
          <CollapsibleDataContent {...contentProps} />
        </ScrollArea>
      )}
    />
  )
}
